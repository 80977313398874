import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./NavItem.scss";

const NavItem = ({ icon, name, url, isActive }) => {
  const { t } = useTranslation();

  return (
    <Link className={`nav-item ${isActive ? "active" : ""}`} to={url}>
      {icon}
      <span>{t(name)}</span>
    </Link>
  );
};

export default NavItem;
