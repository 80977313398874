import Avatar from "components/ui/Avatar";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { ReactComponent as Logo } from "static/images/logo.svg";
import "./Header.scss";
import { Link } from "react-router-dom";
import { getStoredUserData } from "utils/auth";

/* Default app header - titleElement will be visible and navBar will be hidden for mobile layouts */

const EvaluationHeader = ({ title }) => {
  const userDetails = getStoredUserData();
  return (
    <header className="header">
      <Link className="home-link" to="/">
        <Logo className="logo" />
      </Link>
      <Text textStyle={TEXT_STYLES.large} tagName="h1">
        {title}
      </Text>
      <Link to="/profile" className="profile-link">
        <Avatar url={userDetails.photoURL} displayName={userDetails.displayName} />
      </Link>
    </header>
  );
};

export default EvaluationHeader;
