import { ReactComponent as CalendarSmall } from "static/icons/empty_calendar_small.svg";
import { ReactComponent as CalendarBig } from "static/icons/empty_calendar_big.svg";
import Text, { TEXT_STYLES } from "./Text";
import "./LabeledCalendar.scss";

const LabeledCalendar = ({ lable, isBig = false }) => {
  return (
    <div className="calendar">
      {isBig ? <CalendarBig className="calendar-big" /> : <CalendarSmall className="calendar-small" />}
      <Text className={isBig ? "lable-big" : "lable-small"} textStyle={TEXT_STYLES.smallDark}>
        {lable}
      </Text>
    </div>
  );
};

export default LabeledCalendar;
