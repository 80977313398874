import { useTranslation } from "react-i18next";
import "./Button.scss";
import spinner from "static/images/spinner.gif";
import classNames from "classnames";
import { ReactComponent as Lock } from "static/icons/lock.svg";
import { USER_ACTIVITY_STATUS } from "utils/constants";

const classMap = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  PRIMARY_OUTLINE: "primary-outline",
  GREY_OUTLINE: "grey-outline",
  TERTIARY: "tertiary",
  LOCKED: "locked",
};

const Button = ({ onClick, isLoading, disabled, className, type = "PRIMARY", children }) => {
  const { t } = useTranslation();

  return isLoading ? (
    <button className={classNames("button", classMap[type], className)}>
      <img className="spinner" src={spinner} alt={t("general.loading")} />
    </button>
  ) : (
    <button disabled={disabled} className={classNames("button", classMap[type], className)} onClick={onClick}>
      {type === USER_ACTIVITY_STATUS.LOCKED && (
        <>
          <Lock />
          &nbsp;&nbsp;
        </>
      )}
      {children}
    </button>
  );
};

export default Button;
