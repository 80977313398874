import { useTranslation } from "react-i18next";
import "./DownloadButton.scss";
import { ReactComponent as Download } from "static/icons/download.svg";
import spinner from "static/images/spinner_grey.gif";
import classNames from "classnames";
import useDownload from "hooks/useDownload";

const DownloadButton = ({ className, url, fileName, text }) => {
  const { t } = useTranslation();
  const { isLoading, handleDownload } = useDownload(url, fileName);
  return (
    <button className={classNames("download-button", className)} onClick={handleDownload} disabled={isLoading}>
      {isLoading ? <img className="spinner" src={spinner} alt={t("downloadable.downloading")} /> : <Download />}
      <>&nbsp;&nbsp;</>
      {isLoading ? t("downloadable.downloading") : t(text) || t("general.download")}
    </button>
  );
};

export default DownloadButton;
