import Timeline from "components/evaluation/Timeline";
import EvaluationHeader from "components/layout/EvaluationHeader";
import Layout from "components/layout/Layout";
import classNames from "classnames";
import "./Evaluation.scss";
import StepCompleteAnimation from "components/evaluation/StepCompleteAnimation";
import Loader from "components/layout/Loader";
import { fetchSubmittedAnswers, fetchTests, generateReportAndProgram, submitTest } from "api/user/evaluation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getStoredUserData, updateUserState } from "utils/auth";
import { EVALUATION_STEPS, FINAL_EVALUATION_STEPS, USER_STATES } from "utils/constants";

export const EVALUATION_TYPES = {
  INITIAL: "Initial",
  FINAL: "Final",
};

const Evaluation = ({ evaluationType = EVALUATION_TYPES.INITIAL }) => {
  const evaluationSteps = evaluationType === EVALUATION_TYPES.INITIAL ? EVALUATION_STEPS : FINAL_EVALUATION_STEPS;
  const sessionStorageKey = evaluationType === EVALUATION_TYPES.INITIAL ? "currentEvaluationStep" : "currentFinalEvaluationStep";
  const [currentStep, setCurrentStep] = useState(Number(sessionStorage.getItem(sessionStorageKey)) || 0);
  const [steps, setSteps] = useState(
    evaluationSteps.map((step, idx) => ({
      ...step,
      status: idx <= currentStep ? (idx === currentStep ? "ACTIVE" : "COMPLETE") : "INCOMPLETE",
    }))
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingAnswers, setIsSubmittingAnswers] = useState(false);
  const [showStepCompleteAnimation, setShowStepCompleteAnimation] = useState(false);
  const [tests, setTests] = useState([]);
  const [submittedAnswers, setSubmittedAnswers] = useState({});
  const userDetails = getStoredUserData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchEvaluationTests = async () => {
    try {
      const tests = await fetchTests();
      setTests(tests);
    } finally {
      setIsLoading(false);
    }
  };

  const restoreUserEvaluationProgress = async () => {
    try {
      const answers = await fetchSubmittedAnswers(evaluationType);
      setSubmittedAnswers(answers);
    } catch {}
  };

  useEffect(() => {
    fetchEvaluationTests();
    if (userDetails.state === USER_STATES.INITIAL_TEST_IN_PROGRESS || userDetails.state === USER_STATES.FINAL_TEST_IN_PROGRESS) {
      restoreUserEvaluationProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.state]);

  const submitTestAnswers = async (key, answers) => {
    try {
      setIsSubmittingAnswers(true);
      const { state } = await submitTest(key, answers, evaluationType);
      updateUserState(state);
      if (steps[currentStep].id === "test_4") {
        // TODO: Add a better loader here since wait time is around 8-9s
        // Generate Report and Program
        const { report, program, state } = await generateReportAndProgram(evaluationType);
        updateUserState(state);
        if (type === EVALUATION_TYPES.INITIAL) {
          sessionStorage.setItem("initialReport", JSON.stringify(report));
          sessionStorage.setItem("programSummary", JSON.stringify(program));
        } else if (type === EVALUATION_TYPES.FINAL) {
          sessionStorage.setItem("finalReport", JSON.stringify(report));
        }
        onContinue();
      } else {
        setShowStepCompleteAnimation(true);
        setTimeout(() => {
          setShowStepCompleteAnimation(false);
          onContinue();
        }, 3000);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSubmittingAnswers(false);
    }
  };

  const onContinue = () => {
    if (currentStep < evaluationSteps.length - 1) {
      const updatedSteps = [...steps];
      updatedSteps[currentStep].status = "COMPLETE";
      updatedSteps[currentStep + 1].status = "ACTIVE";
      setSteps(updatedSteps);
      setCurrentStep(currentStep + 1);
      setShowStepCompleteAnimation(false);
      sessionStorage.setItem(sessionStorageKey, Number(currentStep) + 1);
    } else {
      // Navigate user to "/" after finishing all the on-boarding steps
      navigate("/");
    }
  };

  const onPrevious = () => {
    if (currentStep > 0) {
      const updatedSteps = [...steps];
      updatedSteps[currentStep].status = "UNDONE";
      updatedSteps[currentStep - 1].status = "ACTIVE";
      setSteps(updatedSteps);
      setCurrentStep(currentStep - 1);
      setShowStepCompleteAnimation(false);
      sessionStorage.setItem(sessionStorageKey, Number(currentStep) - 1);
    }
  };

  const { ViewComponent, id, key, type, containerClass } = steps[currentStep];

  let currentStepView;
  if (isLoading) {
    return (
      <Layout customHeader={<EvaluationHeader title={t(`evaluation.${id}`)} />} hideNav>
        <Loader />
      </Layout>
    );
  } else if (showStepCompleteAnimation) {
    currentStepView = <StepCompleteAnimation testKey={key} onContinue={onContinue} onPrevious={onPrevious} />;
  } else if (ViewComponent) {
    const stepProps = {
      data: steps[currentStep],
      onContinue,
      onPrevious,
    };
    if (type === "TEST") {
      stepProps.testData = tests.find((test) => test.id === key);
      stepProps.submitTestAnswers = submitTestAnswers;
      stepProps.isSubmittingAnswers = isSubmittingAnswers;
      stepProps.submittedAnswers = submittedAnswers;
    } else if (type === "REPORT") {
      if (evaluationType === EVALUATION_TYPES.INITIAL) {
        stepProps.fromOnBoarding = true;
      } else {
        stepProps.fromTestCompletion = true;
      }
    }
    currentStepView = <ViewComponent {...stepProps} evaluationType={evaluationType} />;
  }

  return (
    <Layout customHeader={<EvaluationHeader title={t(`evaluation.${id}`)} />} hideNav>
      <Timeline items={steps} />
      <div className={classNames("view-container", containerClass)}>{currentStepView}</div>
    </Layout>
  );
};

export default Evaluation;
