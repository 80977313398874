import EvaluationLayout from "./EvaluationLayout";
import Mcq from "components/mcq/Mcq";
import "./TestStep.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { EVALUATION_TYPES } from "pages/Evaluation";

const TestStep = ({ data, testData, submitTestAnswers, evaluationType, submittedAnswers, isSubmittingAnswers, onPrevious }) => {
  const { key } = data;
  const { description, options, optionsWeight, questions } = testData;
  const sessionStorageKey = evaluationType === EVALUATION_TYPES.INITIAL ? "initialTestAnswers" : "finalTestAnswers";

  const initializeUserAnswers = useCallback(() => {
    // Return saved answers from cache OR initialize it from scratch using API data
    const cachedTestAnswers = JSON.parse(sessionStorage.getItem(sessionStorageKey)) || submittedAnswers || {};
    if (cachedTestAnswers[key]) {
      return cachedTestAnswers[key];
    }
    return Object.keys(questions).reduce((prev, cur) => ({ ...prev, [cur]: -1 }), {});
  }, [key, questions, submittedAnswers, sessionStorageKey]);

  const [userAnswers, setUserAnswers] = useState(initializeUserAnswers());
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [fade, setFade] = useState(false);
  const { t } = useTranslation();
  const [questionId, question] = Object.entries(questions)[currentQuestionIdx];
  const totalQuestions = Object.keys(questions).length;
  const isQuestionAnswered = userAnswers[questionId] > -1;
  const areAllQuestionsAnswered = currentQuestionIdx === totalQuestions - 1 && Object.keys(userAnswers).every((question) => userAnswers[question] > -1);
  useEffect(() => {
    // Reset state as each new test is loaded
    setUserAnswers(initializeUserAnswers());
    setCurrentQuestionIdx(0);
  }, [key, initializeUserAnswers]);

  const onMcqAnswerSelect = (questionId, optionWeight) => {
    const updatedUserAnswers = { ...userAnswers, [questionId]: optionWeight };
    setUserAnswers(updatedUserAnswers);
    cacheUserAnswers(updatedUserAnswers);
    if (currentQuestionIdx !== totalQuestions - 1) {
      setTimeout(() => {
        setFade(true);
      }, 200);
      setTimeout(() => {
        showNextQuestion();
        setFade(false);
      }, 500);
    }
  };

  const cacheUserAnswers = (updatedUserAnswers) => {
    const cachedTestAnswers = JSON.parse(sessionStorage.getItem(sessionStorageKey)) || {};
    sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        ...cachedTestAnswers,
        [key]: updatedUserAnswers,
      })
    );
  };

  const showNextQuestion = () => {
    if (currentQuestionIdx < totalQuestions - 1) {
      setCurrentQuestionIdx(currentQuestionIdx + 1);
    } else {
      submitTestAnswers(key, userAnswers);
    }
  };

  const showPreviousQuestion = () => {
    if (currentQuestionIdx > 0) {
      setCurrentQuestionIdx(currentQuestionIdx - 1);
    } else {
      onPrevious();
    }
  };

  return (
    <EvaluationLayout
      onContinue={showNextQuestion}
      onPrevious={showPreviousQuestion}
      isNextBtnDisabled={!isQuestionAnswered}
      isBtnLoading={isSubmittingAnswers}
      isPrimaryBtnDisabled={!areAllQuestionsAnswered}
      primaryBtnText={t("general.submit")}
    >
      <div className="test-step">
        <div className="mcq-card">
          <Text className="description" textStyle={TEXT_STYLES.medium}>
            {description}
          </Text>
          <Mcq
            className={classNames({ hidden: fade })}
            questionId={questionId}
            checkedOption={optionsWeight.indexOf(userAnswers[questionId])}
            question={`${currentQuestionIdx + 1}. ${question}`}
            options={options}
            optionsWeight={optionsWeight}
            onChange={onMcqAnswerSelect}
          />
        </div>
        <Text textStyle={TEXT_STYLES.mediumLight}>
          {currentQuestionIdx + 1}/{totalQuestions}
        </Text>
      </div>
    </EvaluationLayout>
  );
};

export default TestStep;
