import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const Chart = ({ data, children }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={200}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis padding={{ top: 50 }} />
        <Tooltip separator=": " cursor={{ fill: "#e1e8ed" }} />
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Chart;
