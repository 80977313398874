import { useEffect, useState } from "react";

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const useDeviceDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isSmall = width >= breakpoints.sm;
  const isMedium = width >= breakpoints.md;
  const isLarge = width >= breakpoints.lg;
  const isXLarge = width >= breakpoints.xl;
  const isXXLarge = width >= breakpoints.xxl;

  return { isSmall, isMedium, isLarge, isXLarge, isXXLarge };
};

export default useDeviceDetector;
