import errorImg from "static/images/error.png";
import logo from "static/images/logo.svg";
import image_404 from "static/images/404.png";
import "./ErrorPage.scss";
import Button from "components/ui/buttons/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const propsMap = {
  APPLICATION_ERROR: {
    image: errorImg,
    text: "error.something_went_wrong",
    buttonText: "error.reload_btn",
  },
  PAGE_NOT_FOUND_ERROR: {
    image: image_404,
    text: "error.page_not_found",
    buttonText: "error.go_home_btn",
  },
};

const ErrorPage = ({ type = "APPLICATION_ERROR" }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClick = () => {
    if (type === "APPLICATION_ERROR") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };
  const { text, image, buttonText } = propsMap[type];
  return (
    <div className="error-page">
      <img className="logo" src={logo} alt={t("general.app_logo")}></img>
      <img className="graphic" src={image} alt={t(text)}></img>
      <h2>{t(text)}</h2>
      <Button type="PRIMARY" onClick={onClick}>
        {t(buttonText)}
      </Button>
    </div>
  );
};

export default ErrorPage;
