import classNames from "classnames";
import "./McqOption.scss";

const McqOption = ({ className, selected, incorrect, correct, children, onChange }) => {
  return (
    <div className={classNames("mcq-option", className, { selected: selected, incorrect: incorrect, correct: correct })} onClick={onChange}>
      {children}
    </div>
  );
};

export default McqOption;
