import { useState } from "react";

const useDownload = (url, fileName) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      // Fetch the file
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Convert the response into a blob
      const blob = await response.blob();
      // Create a link and download the file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", fileName); // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error while downloading the file:", error);
    }
  };
  return { isLoading, handleDownload };
};

export default useDownload;
