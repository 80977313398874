import { useRef } from "react";

const useThrottle = (func, delay) => {
  const timeoutRef = useRef(null);

  return (...args) => {
    if (timeoutRef.current) {
      return; // If there's an active timeout, ignore the call
    }

    // Set a timeout to clear after the delay
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null; // Clear the timeout after the delay
    }, delay);

    // Invoke the throttled function
    func(...args);
  };
};

export default useThrottle;
