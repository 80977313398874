import "./Checkbox.scss";

const Checkbox = ({ label, value, onChange }) => {
  return (
    <label className="checkbox">
      {label}
      <input type="checkbox" value={value} className="checkbox-input" onChange={onChange}></input>
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
