import NavBar from "components/navbar/NavBar";
import Avatar from "components/ui/Avatar";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { ReactComponent as Logo } from "static/images/logo.svg";
import "./Header.scss";
import useDeviceDetector from "hooks/useDeviceDetector";
import { Link } from "react-router-dom";
import { getStoredUserData } from "utils/auth";

/* Default app header - titleElement will be visible and navBar will be hidden for mobile layouts */

const Header = ({ title }) => {
  const { isLarge } = useDeviceDetector();
  const userDetails = getStoredUserData();

  return (
    <header className="header">
      <Link className="home-link" to="/">
        <Logo className="logo" />
      </Link>
      {!isLarge && (
        <Text className={"header-title"} textStyle={TEXT_STYLES.extraLargeLight}>
          {title}
        </Text>
      )}
      {isLarge && <NavBar />}

      <Link to="/profile" className={isLarge ? "profile-link" : "profile-link-with-margin"}>
        <Avatar url={userDetails.photoURL} displayName={userDetails.displayName} />
      </Link>
    </header>
  );
};

export default Header;
