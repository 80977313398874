import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import "./Timeline.scss";
import { useTranslation } from "react-i18next";
import Text, { TEXT_STYLES } from "components/ui/Text";

const Timeline = ({ items }) => {
  const { t } = useTranslation();
  return (
    <ul className="timeline">
      {items.map((item, idx) => (
        <li
          key={`timeline_item_${idx}`}
          className={classNames("item", {
            "no-animate": item.status === "INCOMPLETE",
            checked: item.status === "COMPLETE",
            unfilled: item.status === "UNDONE",
            filled: item.status === "ACTIVE",
          })}
        >
          <div className="circle" data-tooltip-id={`timeline-tooltip-${idx}`}>
            {item.status === "COMPLETE" && (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <polyline className="check" fill="none" stroke="#ffffff" strokeWidth="14" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
              </svg>
            )}
          </div>
          <Text className="label" textStyle={TEXT_STYLES.extraSmall}>
            {t(`evaluation.${item.id}`)}
          </Text>
          <Tooltip className="tooltip" id={`timeline-tooltip-${idx}`} place="bottom" content={t(`evaluation.${item.id}`)} />
        </li>
      ))}
    </ul>
  );
};

export default Timeline;
