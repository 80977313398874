import "./SummaryItem.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";

const SummaryItem = ({ title, category }) => {
  return (
    <div className="summary-item-container">
      <Text className={"title"} textStyle={TEXT_STYLES.small}>
        {title}
      </Text>
      {category.map((module, index) => (
        <div key={index} className="item">
          <Text textStyle={TEXT_STYLES.small}>{`${index + 1}. ${module.name}`}</Text>
        </div>
      ))}
    </div>
  );
};

export default SummaryItem;
