import Lottie from "react-lottie-player";
import loader from "static/lottie/loader.json";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loader-container">
      <Lottie loop animationData={loader} play className="spinner" />
    </div>
  );
};

export default Loader;
