import "./SummaryItem.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import DownloadButton from "components/ui/buttons/DownloadButton";
import { ReactComponent as PdfIcon } from "static/icons/pdf.svg";
import { ReactComponent as AudioIcon } from "static/icons/audio.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/ui/buttons/Button";

const DownloadableItem = ({ category }) => {
  const { t } = useTranslation();
  return (
    <div className="summary-item-container">
      {category.map(
        (module, index) =>
          (module.summary.id || module.submodules.length > 0) && (
            <React.Fragment key={index}>
              <Text className={"title"} textStyle={TEXT_STYLES.small}>
                {module.name}
              </Text>
              {module.summary.id && (
                <div className={"item with-margin"}>
                  {module.summary.type === "DOC" ? <PdfIcon /> : null}
                  <Text textStyle={TEXT_STYLES.small}>{t("downloadable.module_summary")}</Text>
                  {module.status === "COMPLETED" ? (
                    <DownloadButton className={"action-button"} url={module.summary.url} fileName={module.summary.handle} />
                  ) : (
                    <Button className="action-button" type={"LOCKED"}>
                      {t("exercise.locked")}
                    </Button>
                  )}
                </div>
              )}
              {module.submodules.length > 0 &&
                module.submodules.map((submodule, index) => (
                  <div key={index} className={"item with-margin"}>
                    {submodule.type === "DOC" ? <PdfIcon /> : submodule.type === "AUDIO" ? <AudioIcon /> : null}
                    <Text textStyle={TEXT_STYLES.small}>{submodule.title}</Text>
                    {submodule.status === "COMPLETED" ? (
                      <DownloadButton className={"action-button"} url={submodule.url} fileName={submodule.handle} />
                    ) : (
                      <Button className="action-button" type={"LOCKED"}>
                        {t("exercise.locked")}
                      </Button>
                    )}
                  </div>
                ))}
            </React.Fragment>
          )
      )}
    </div>
  );
};

export default DownloadableItem;