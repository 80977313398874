import React from "react";
import "./Text.scss";
import classNames from "classnames";
import DOMPurify from "dompurify";

export const TEXT_STYLES = {
  extraSmall: "extra-small",
  small: "small",
  smallLight: "small-light",
  smallPrimary: "small-primary",
  smallLightItalic: "small-light-italic",
  smallDark: "small-dark",
  smallTeal: "small-teal",
  smallSecondary: "small-secondary",
  smallGrey: "small-grey",
  medium: "medium",
  mediumDark: "medium-dark",
  mediumLight: "medium-light",
  mediumWhite: "medium-white",
  mediumSecondary: "medium-secondary",
  large: "large",
  largeLight: "large-light",
  largeLightItalic: "large-light-italic",
  extraLarge: "extra-large",
  extraLargeLight: "extra-large-light",
};

const Text = ({ tagName = "span", onClick, children, className, textStyle, setInnerHtmlDangerously }) => {
  const CustomElement = `${tagName}`;
  return setInnerHtmlDangerously ? (
    <CustomElement
      className={classNames("text", { [className]: className, [textStyle]: textStyle })}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }}
      onClick={onClick}
    />
  ) : (
    <CustomElement className={classNames("text", { [className]: className, [textStyle]: textStyle })} onClick={onClick}>
      {children}
    </CustomElement>
  );
};

export default Text;
