import "./Avatar.scss";

const Avatar = ({ url, displayName }) => {
  displayName = displayName || "User";
  const initials = displayName
    ?.split(" ")
    .map((parts) => parts.slice(0, 1))
    .join("");
  return url ? <img className="avatar" src={url} alt="User avatar"></img> : <div className="initials">{initials}</div>;
};

export default Avatar;
