import { useTranslation } from "react-i18next";
import { Bar, Legend } from "recharts";

const CHART_COLORS = {
  PRIMARY: "#59b3ff",
  SECONDARY: "#50bdb4",
};

const useChartData = (data) => {
  const { t } = useTranslation();

  // If more than 1 group in bar chart
  if (data.length > 1) {
    return {
      chartData: data.map((group) => ({
        name: group.title,
        Pre: group.pre,
        Post: group.post,
      })),
      bars: (
        <>
          <Legend layout="vertical" verticalAlign="middle" align="right" wrapperStyle={{ paddingLeft: "3rem" }} />
          <Bar dataKey="Pre" fill={CHART_COLORS.PRIMARY} maxBarSize={60} />
          <Bar dataKey="Post" fill={CHART_COLORS.SECONDARY} maxBarSize={60} />
        </>
      ),
    };
  }

  // Only one group in bar chart, so spread it apart
  else {
    return {
      chartData: [
        {
          name: t("general.pre"),
          Value: data[0].pre,
          fill: CHART_COLORS.PRIMARY,
        },
        {
          name: t("general.post"),
          Value: data[0].post,
          fill: CHART_COLORS.SECONDARY,
        },
      ],
      bars: <Bar dataKey="Value" maxBarSize={60} />,
    };
  }
};

export default useChartData;
