import Header from "components/layout/Header";
import NavBar from "components/navbar/NavBar";
import useDeviceDetector from "hooks/useDeviceDetector";
import "./Layout.scss";

/* Basic page layout - Navbar renders at the bottom for mobile layouts */

const Layout = ({ hideNav, customHeader, children, title }) => {
  const { isLarge } = useDeviceDetector();
  return (
    <>
      {customHeader ? customHeader : <Header title={title} />}
      <main className="main-section">{children}</main>
      {!isLarge && !hideNav && <NavBar />}
    </>
  );
};

export default Layout;
