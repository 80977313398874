import router from "components/router/Router";

const makeAPICall = async ({ method, url, params, body, headers }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryParams = new URLSearchParams(params);
      const requestHeaders = {
        "Content-Type": "application/json",
        ...headers,
      };
      const resObj = await fetch(`${url}?${queryParams.toString()}`, {
        method,
        body,
        headers: requestHeaders,
      });
      if (resObj.status === 401) {
        reject();
        router.navigate("/login");
      }
      const { status, response } = await resObj.json();
      if (resObj.status !== 200 || status.isError) {
        throw new Error(status.message);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const post = ({ url, params, body, headers }) => makeAPICall({ url, params, body, headers, method: "POST" });
const get = ({ url, params, body, headers }) => makeAPICall({ url, params, body, headers, method: "GET" });

export { post, get };
