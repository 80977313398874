import { useTranslation } from "react-i18next";
import Checkbox from "components/ui/inputs/Checkbox";
import { useState } from "react";
import "./TncStep.scss";
import EvaluationLayout from "./EvaluationLayout";
import { tos } from "utils/constants";

const TncStep = ({ data, onContinue, onPrevious }) => {
  const { primaryBtnText } = data;
  const { t } = useTranslation();
  const [isTncChecked, setIsTncChecked] = useState(false);

  const onTncChange = () => {
    setIsTncChecked(!isTncChecked);
  };

  return (
    <EvaluationLayout isPrimaryBtnDisabled={!isTncChecked} isNextBtnDisabled={!isTncChecked} primaryBtnText={primaryBtnText} onContinue={onContinue} onPrevious={onPrevious}>
      <div className="tnc">
        <div className="text-wrap" dangerouslySetInnerHTML={{ __html: tos }}></div>
        <Checkbox label={t("evaluation.tnc_accept")} value={isTncChecked} onChange={onTncChange} />
      </div>
    </EvaluationLayout>
  );
};

export default TncStep;
