import Text from "components/ui/Text";
import "./Mcq.scss";
import classNames from "classnames";
import McqOption from "./McqOption";

const Mcq = ({ options, optionsWeight: optionsWeightFromProps, className, checkedOption, correctOption, questionId, question, onChange }) => {
  const optionsWeight = optionsWeightFromProps || options;
  return (
    <div className={classNames("mcq", className)}>
      <Text>{question}</Text>
      <div className="options">
        {options.map((option, idx) => (
          <McqOption
            className="option"
            key={`${questionId}_option_${idx}`}
            selected={checkedOption === optionsWeight[idx]}
            incorrect={correctOption && checkedOption && checkedOption === optionsWeight[idx] && checkedOption !== correctOption}
            correct={correctOption && checkedOption && checkedOption === optionsWeight[idx] && checkedOption === correctOption}
            onChange={() => onChange(questionId, optionsWeight[idx])}
          >
            {option}
          </McqOption>
        ))}
      </div>
    </div>
  );
};

export default Mcq;
