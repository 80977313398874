import { RouterProvider } from "react-router-dom";
import router from "components/router/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { I18nextProvider } from "react-i18next";
import i18n from "services/translator";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar />
    </I18nextProvider>
  );
};

export default App;
