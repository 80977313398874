import "./MoodTracker.scss";
import Face0 from "static/icons/face_0.svg";
import Face1 from "static/icons/face_1.svg";
import Face2 from "static/icons/face_2.svg";
import Face3 from "static/icons/face_3.svg";
import Face4 from "static/icons/face_4.svg";
import Text, { TEXT_STYLES } from "./Text";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Loader from "components/layout/Loader";

export const MOOD_TYPE = {
  BEFORE: "before",
  AFTER: "after",
};

const MoodTracker = ({ moodType, recordMood }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const moods = [
    {
      icon: Face0,
      title: t("mood.very_sad"),
      score: 0,
    },
    {
      icon: Face1,
      title: t("mood.sad"),
      score: 1,
    },
    {
      icon: Face2,
      title: t("mood.neutral"),
      score: 2,
    },
    {
      icon: Face3,
      title: t("mood.happy"),
      score: 3,
    },
    {
      icon: Face4,
      title: t("mood.very_happy"),
      score: 4,
    },
  ];

  const handleMoodClick = (moodType, moodScore) => {
    setLoading(true);
    setTimeout(function () {
      recordMood(moodType, moodScore);
    }, 2000);
  };
  
  if (loading) {
    return <Loader className="loader" />;
  }

  return (
    <div className="mood-container">
      {moods.map((mood, index) => (
        <div key={index} className="mood-item" onClick={() => handleMoodClick(moodType, mood.score)}>
          <img src={mood.icon} alt={mood.title} />
          <Text className={"mood-item-lable"} textStyle={TEXT_STYLES.medium}>
            {mood.title}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default MoodTracker;
