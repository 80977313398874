import { firebaseAuth } from "services/firebase";
import { get, post } from "utils/network";

async function getProgramSummary() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-program-summary`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getAllWeeksProgram() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-weekly-program`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}
async function getSingleWeekProgram(weekId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-single-week-program`,
        headers: { Authorization: `Bearer ${token}` },
        params: { week_id: weekId },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getModule(weekId, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-module`,
        headers: { Authorization: `Bearer ${token}` },
        params: { week_id: weekId, module_id: moduleId },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getConclusionModule() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-conclusion-module`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function completeConclusionModule() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/complete-conclusion-module`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateProgress(weekId, moduleId, submoduleId, progress) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/update-progress`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ week_id: weekId, module_id: moduleId, submodule_id: submoduleId, progress }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateEllisData(weekId, moduleId, submoduleId, userSelections) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/update-ellis-data`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ week_id: weekId, module_id: moduleId, submodule_id: submoduleId, user_selections: userSelections }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateMood(weekId, moduleId, submoduleId, moodType, moodScore) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/update-mood`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ week_id: weekId, module_id: moduleId, submodule_id: submoduleId, type: moodType, mood_score: moodScore }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function setMcqPassed(weekId, moduleId, submoduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/set-mcq-passed`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ week_id: weekId, module_id: moduleId, submodule_id: submoduleId }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

export {
  getProgramSummary,
  getAllWeeksProgram,
  updateEllisData,
  getModule,
  getConclusionModule,
  updateMood,
  completeConclusionModule,
  getSingleWeekProgram,
  updateProgress,
  setMcqPassed,
};
