import { getReport } from "api/user/report";
import ErrorPage from "components/layout/ErrorPage";
import Loader from "components/layout/Loader";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStoredUserData } from "utils/auth";
import "./InitialReport.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import AnxietyManifest from "static/images/anxiety_manifest.svg";
import AnxietyResponse from "static/images/anxiety_response.svg";
import AnxietyGenerate from "static/images/anxiety_generate.svg";
import AdditionalTip from "static/images/additional_tip.svg";
import Button from "components/ui/buttons/Button";
import { InitialReportBlock } from "./ReportBlock";

const REPORT_BLOCK_IMAGE_MAP = {
  how_do_stress_anxiety_manifest: AnxietyManifest,
  anxiety_manifestation: AnxietyResponse,
  anxiety_in_situations: AnxietyGenerate,
  anger_management: AdditionalTip,
};

const InitialReport = ({ fromOnBoarding, onContinue }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [isError, setIsError] = useState(false);
  const userDetails = getStoredUserData();

  const getInitialReport = useCallback(async () => {
    try {
      const data = await getReport("Initial");
      setReportData(data);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem("initialReport"));
    if (cachedData) {
      setReportData(cachedData);
      setLoading(false);
    } else {
      getInitialReport();
    }
  }, [getInitialReport]);

  if (loading) {
    // Display a loader while data is being fetched
    return <Loader />;
  }
  if (isError) {
    return <ErrorPage />;
  }

  if (!reportData.isReportAvailable) {
    return (
      <div className="report-not-available">
        <Text textStyle={TEXT_STYLES.smallLight} tagName="p">
          {reportData.message}
        </Text>
      </div>
    );
  }
  
  return (
    <div className="report-content">
      <div className="report-title">
        <Text className="text-uppercase" tagName="span" textStyle="large" textTransform="uppercase">
          {t("initial_report.title")}
        </Text>
      </div>
      <div className="greeting-block">
        <Text tagName="span" textStyle={TEXT_STYLES.smallGrey}>
          {t("initial_report.hello")}{" "}
        </Text>
        <Text tagName="span" textStyle={TEXT_STYLES.medium}>
          {`${userDetails.displayName},`}
        </Text>
      </div>
      <Text textStyle={TEXT_STYLES.medium}>{t("initial_report.description_header")}</Text>
      <div className="initial-description-block">
        <Text textStyle={TEXT_STYLES.smallGrey}>{t("initial_report.description")}</Text>
      </div>
      <div className="report-blocks">
        {reportData.report.map((block, index) => (
          <InitialReportBlock key={index} blockTitle={block.title} blockImage={REPORT_BLOCK_IMAGE_MAP[block.id]} blockDescription={block.content} />
        ))}
      </div>
      <Text className="final-description-block" textStyle={TEXT_STYLES.smallGrey}>
        {t("initial_report.final_description")}
      </Text>
      <Text className="disclaimer-title" textStyle={TEXT_STYLES.small}>
        {t("initial_report.disclaimer_title")}
      </Text>
      <Text className="disclaimer" textStyle={TEXT_STYLES.smallGrey}>
        {t("initial_report.disclaimer")}
      </Text>
      <Text className="warning" textStyle={TEXT_STYLES.smallLightItalic}>
        {t("initial_report.warning")}
      </Text>
      <Text className="text-uppercase good-luck" textStyle={TEXT_STYLES.largeLightItalic}>
        {t("initial_report.good_luck")}
      </Text>
      {fromOnBoarding ? (
        <Button className="continue-btn" onClick={onContinue} type="PRIMARY">
          {t("general.continue")}
        </Button>
      ) : null}
    </div>
  );
};

export default InitialReport;
