import Text, { TEXT_STYLES } from "components/ui/Text";
import { useTranslation } from "react-i18next";
import "./Dialog.scss";
import Button from "./buttons/Button";
import classNames from "classnames";

const Dialog = ({ className, title, onDismiss, image, children, buttonText, buttonClick }) => {
  const { t } = useTranslation();
  const onClickOutside = (e) => {
    if (e.target.id === "overlay") {
      onDismiss();
    }
  };
  return (
    <div id="overlay" className={classNames("dialog-overlay", className)} onClick={onClickOutside}>
      <div className={"dialog-container"}>
        <Text textStyle={TEXT_STYLES.largeLight}>{title}</Text>
        {image && <img src={image} alt={t("general.dialog_image")} />}
        {children}
        <Button className="action-button" onClick={buttonClick} type={"SECONDARY"}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default Dialog;
