import LabeledCalendar from "components/ui/LabeledCalendar";
import "./SummaryCard.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import Separator from "components/ui/Separator";
import SummaryItem from "./SummaryItem";
import { useTranslation } from "react-i18next";
import DownloadableItem from "./DownloadableItem";

const SummaryCard = ({ weekCount, weeklySummary, isDownloadable = false }) => {
  const { t } = useTranslation();
  return (
    <div className="summary-card">
      <div className="title-section">
        <LabeledCalendar lable={weekCount} />
        <Text textStyle={TEXT_STYLES.mediumDark}>{t(`program.${weeklySummary.id}`)}</Text>
      </div>
      {weeklySummary.relaxation ? (
        <>
          <Separator className={"divider"} />
          {isDownloadable ? <DownloadableItem category={weeklySummary.relaxation} /> : <SummaryItem title={t("program.relaxation")} category={weeklySummary.relaxation} />}
        </>
      ) : null}

      {weeklySummary.psychoeducation ? (
        <>
          <Separator className={"divider"} />
          {isDownloadable ? (
            <DownloadableItem category={weeklySummary.psychoeducation} />
          ) : (
            <SummaryItem title={t("program.psychoeducation")} category={weeklySummary.psychoeducation} />
          )}
        </>
      ) : null}
    </div>
  );
};

export default SummaryCard;
