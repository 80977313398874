import Text, { TEXT_STYLES } from "components/ui/Text";
import "./Summary.scss";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import Loader from "components/layout/Loader";
import ErrorPage from "components/layout/ErrorPage";
import { getProgramSummary } from "api/user/program";
import Button from "components/ui/buttons/Button";
import SummaryCard from "./SummaryCard";

const Summary = ({ onContinue, fromOnboarding = true }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [programSummaryData, setProgramSummary] = useState([]);
  const [isError, setIsError] = useState(false);

  const getProgramData = useCallback(async () => {
    try {
      const data = await getProgramSummary();
      setProgramSummary(data);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem("programSummary"));
    if (cachedData) {
      setProgramSummary(cachedData);
      setLoading(false);
    } else {
      getProgramData();
    }
  }, [getProgramData]);

  if (loading) {
    // Display a loader while data is being fetched
    return <Loader />;
  }
  if (isError) {
    return <ErrorPage />;
  }
  return (
    <div className="summary-container">
      <Text className={"title-card"} textStyle={TEXT_STYLES.mediumLight}>
        {t("program.personalised_title")}
      </Text>
      <div className="programs">
        {programSummaryData.map((weeklySummary, index) => (
          <SummaryCard key={index} weekCount={index + 1} weeklySummary={weeklySummary} />
        ))}
      </div>
      {fromOnboarding && (
        <Button className="continue-btn" onClick={onContinue} type="PRIMARY">
          {t("general.continue")}
        </Button>
      )}
    </div>
  );
};

export default Summary;
