import React from "react";
import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ErrorPage from "components/layout/ErrorPage";
import { EVALUATION_TYPES } from "pages/Evaluation";

const router = createBrowserRouter([
  {
    path: "*",
    element: <ErrorPage type="PAGE_NOT_FOUND_ERROR" />,
  },
  {
    path: "/register",
    async lazy() {
      const { default: Registration } = await import("pages/Registration");
      return {
        Component: Registration,
      };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    async lazy() {
      const { default: Login } = await import("pages/Login");
      return {
        Component: Login,
      };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    async lazy() {
      const { default: ForgotPassword } = await import("pages/ForgotPassword");
      return {
        Component: ForgotPassword,
      };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/create-profile",
    async lazy() {
      const { default: CreateUserProfile } = await import("pages/CreateUserProfile");
      return {
        Component: CreateUserProfile,
      };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "evaluation",
        async lazy() {
          const { default: Evaluation } = await import("pages/Evaluation");
          return {
            Component: Evaluation,
          };
        },
      },
      {
        index: true,
        async lazy() {
          const { default: Home } = await import("pages/Home");
          return {
            Component: Home,
          };
        },
      },
      {
        path: "home",
        async lazy() {
          const { default: Home } = await import("pages/Home");
          return {
            Component: Home,
          };
        },
      },
      {
        path: "program",
        async lazy() {
          const { default: Program } = await import("pages/Program");
          return {
            Component: Program,
          };
        },
      },
      {
        path: "program/:weekId",
        async lazy() {
          const { default: WeeklyProgram } = await import("pages/WeeklyProgram");
          return {
            Component: WeeklyProgram,
          };
        },
      },
      {
        path: "program/:weekId/:moduleId/:submoduleId",
        async lazy() {
          const { default: Submodule } = await import("pages/Submodule");
          return {
            Component: Submodule,
          };
        },
      },
      {
        path: "program/conclusion",
        async lazy() {
          const { default: Conclusion } = await import("pages/Conclusion");
          return {
            Component: Conclusion,
          };
        },
      },
      {
        path: "program/summary",
        async lazy() {
          const { default: ProgramSummary } = await import("pages/ProgramSummary");
          return {
            Component: ProgramSummary,
          };
        },
      },
      {
        path: "downloadable",
        async lazy() {
          const { default: Downloadable } = await import("pages/Downloadable");
          return {
            Component: Downloadable,
          };
        },
      },
      {
        path: "final-evaluation",
        async lazy() {
          const { default: Evaluation } = await import("pages/Evaluation");
          return {
            Component: () => <Evaluation evaluationType={EVALUATION_TYPES.FINAL} />,
          };
        },
      },
      {
        path: "report",
        async lazy() {
          const { default: Report } = await import("pages/Report");
          return {
            Component: Report,
          };
        },
      },
      {
        path: "support",
        async lazy() {
          const { default: Support } = await import("pages/Support");
          return {
            Component: Support,
          };
        },
      },
      {
        path: "support/faq",
        async lazy() {
          const { default: Faq } = await import("pages/Faq");
          return {
            Component: Faq,
          };
        },
      },
      {
        path: "profile",
        async lazy() {
          const { default: Profile } = await import("pages/Profile");
          return {
            Component: Profile,
          };
        },
      },
    ],
  },
]);

export default router;
