import { firebaseAuth } from "services/firebase";
import { get, post } from "utils/network";

async function fetchTests() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/test-data`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function submitTest(testId, answers, testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/answer-test`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ answers, testId, testType, overwrite: true }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function generateReportAndProgram(testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/generate-report-and-program`,
        headers: { Authorization: `Bearer ${token}` },
        params: { type: testType },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function fetchSubmittedAnswers(testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/test-answers-data`,
        headers: { Authorization: `Bearer ${token}` },
        params: { testType },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}
export { fetchTests, submitTest, fetchSubmittedAnswers, generateReportAndProgram };
