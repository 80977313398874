import Dialog from "components/ui/Dialog";
import { useTranslation } from "react-i18next";
import Text, { TEXT_STYLES } from "components/ui/Text";
import "./ConfirmationDialog.scss";

const ConfirmationDialog = ({ onConfirm, onDismiss }) => {
  const { t } = useTranslation();

  return (
    <Dialog onDismiss={onDismiss} className="on-continue-dialog" title={t("evaluation.confirm_start_test_title")} buttonText={t("general.continue")} buttonClick={onConfirm}>
      <Text textStyle={TEXT_STYLES.mediumLight}>{t("evaluation.confirm_start_test_description")}</Text>
    </Dialog>
  );
};

export default ConfirmationDialog;
