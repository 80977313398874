import { firebaseAuth } from "services/firebase";
import { get } from "utils/network";

async function getReport(reportType) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-report`,
        headers: { Authorization: `Bearer ${token}`},
        params: { type: reportType },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}
export { getReport };
