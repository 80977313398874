import { ReactComponent as HomeIcon } from "static/icons/home.svg";
import { ReactComponent as DownloadableIcon } from "static/icons/downloadable.svg";
import { ReactComponent as ProgramIcon } from "static/icons/program.svg";
import { ReactComponent as ReportIcon } from "static/icons/report.svg";
import { ReactComponent as SupportIcon } from "static/icons/support.svg";
import NavItem from "./NavItem";
import "./NavBar.scss";
import { useLocation } from "react-router-dom";

const items = [
  {
    name: "nav.home",
    icon: <HomeIcon />,
    url: "/home",
    isIndex: true,
  },
  {
    name: "nav.program",
    icon: <ProgramIcon />,
    url: "/program",
  },
  {
    name: "nav.downloadable",
    icon: <DownloadableIcon />,
    url: "/downloadable",
  },
  {
    name: "nav.report",
    icon: <ReportIcon />,
    url: "/report?view=1",
  },
  {
    name: "nav.support",
    icon: <SupportIcon />,
    url: "/support",
  },
];

const NavBar = () => {
  const { pathname } = useLocation();
  return (
    <nav className="nav-bar">
      {items.map((item) => (
        <NavItem key={item.name} name={item.name} url={item.url} icon={item.icon} isActive={pathname.includes(item.url) || (item.isIndex && pathname === "/")} />
      ))}
    </nav>
  );
};

export default NavBar;
