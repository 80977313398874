import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enStrings from "languages/en.json";
import esStrings from "languages/es.json";

i18n.use(initReactI18next).init({
  resources: {
    en: enStrings,
    es: esStrings,
  },
  lng: localStorage.getItem("selectedLanguage"),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
