import { firebaseAuth } from "services/firebase";

const handleRememberMe = (isRememberChecked) => {
  if (isRememberChecked) {
    localStorage.setItem("isRememberChecked", isRememberChecked);
  } else {
    localStorage.removeItem("isRememberChecked");
  }
};

const logoutUser = () => {
  localStorage.clear();
  sessionStorage.clear();
  firebaseAuth.signOut();
};

const storeUserData = (userDetails) => {
  if (localStorage.getItem("isRememberChecked")) {
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
  } else {
    sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
  }
};

const updateUserState = (newState) => {
  const userData = getStoredUserData();
  userData.state = newState;
  if (localStorage.getItem("isRememberChecked")) {
    localStorage.setItem("userDetails", JSON.stringify(userData));
  } else {
    sessionStorage.setItem("userDetails", JSON.stringify(userData));
  }
};

const getStoredUserData = () => {
  if (localStorage.getItem("isRememberChecked")) {
    return JSON.parse(localStorage.getItem("userDetails")) || {};
  } else {
    return JSON.parse(sessionStorage.getItem("userDetails")) || {};
  }
};

export { handleRememberMe, logoutUser, storeUserData, updateUserState, getStoredUserData };
