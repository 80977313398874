import { useState } from "react";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { ReactComponent as Expand } from "static/icons/expand.svg";
import { ReactComponent as Collapse } from "static/icons/collapse.svg";
import "./ExpandableCard.scss";
import classNames from "classnames";

const ExpandableCard = ({ title, className, titleTagName = "h3", children, defaultExpand = true }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpand);

  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames("expandable-card", className)}>
      <div className="collapsed-content">
        <Text tagName={titleTagName} className="title" textStyle={TEXT_STYLES.smallDark}>
          {title}
        </Text>
        {isExpanded ? <Collapse onClick={toggleVisibility} /> : <Expand onClick={toggleVisibility} />}
      </div>
      {isExpanded ? <div className="expandable-content">{children}</div> : null}
    </div>
  );
};

export default ExpandableCard;
